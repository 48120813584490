// src/App.js
import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Component/Header';
import ImageCarousel from './Component/ImageCarousel';
import Footer from './Component/Footer';
import Categories from './Component/Categories';
import DetailsPage from './Component/DetailsPage';
import About from './Component/About';
import Gallery from './Component/Gallery';
import Catalogue from './Component/Catalogue';
import TeamStarryNights from './Component/TeamStarryNights';
import Honeymoon from './Component/Categories Details/Honeymoon';
import GroupTours from './Component/Categories Details/GroupTours';
import Backpacking from './Component/Categories Details/BackpackingTours';
import CampingWithTrekking from './Component/Categories Details/CampingWithTrekking';
import Internationals from './Component/Categories Details/Internationals';
import BhaktiYatra from './Component/Categories Details/BhaktiYatra';
import Headerstrip from './Component/Headerstrip';
import QueryForm from './Component/QueryForm';


import Chennai from './Component/Location/Chennai';
import Himachal from './Component/Location/Himachal';
import Kashmir from './Component/Location/Kashmir';
import Kerala from './Component/Location/Kerala';
import Maharashtra from './Component/Location/Maharashtra';
import NEIndia from './Component/Location/NEIndia';
import Rajasthan from './Component/Location/Rajasthan';
import Treks from './Component/Location/Treks';
import Uttrakhand from './Component/Location/Uttrakhand';
import Karnataka from './Component/Location/Karnataka';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Headerstrip />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ImageCarousel />
                <Categories />
                <Catalogue />
                <About />
                <TeamStarryNights />
                <Footer />
              </>
            }
          />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/About" element={<About />} />
          <Route path="/contacts" element={<Footer />} />
          <Route path="/Categories" element={<Categories />} />
          <Route path="/Catalogue" element={<Catalogue />} />
          <Route path="/details/:imageName" element={<DetailsPage />} />
          <Route path="/category/Honeymoon" element={<Honeymoon />} />
          <Route path="/category/Group Tours" element={<GroupTours />} />
          <Route path="/category/Backpacking" element={<Backpacking />} />
          <Route path="/category/Camping with Trekking" element={<CampingWithTrekking />} />
          <Route path="/category/Internationals" element={<Internationals />} />
          <Route path="/category/Bhakti Yatra" element={<BhaktiYatra />} /> 
          <Route path="/QueryForm" element={<QueryForm />} />
          <Route path="/Trending" element={<Catalogue />} />
          
          
          <Route path="/Chennai" element={<Chennai />} />
          <Route path="/Himachal" element={<Himachal />} />
          <Route path="/Kashmir" element={<Kashmir />} />
          <Route path="/Kerala" element={<Kerala />} />
          <Route path="/Maharashtra" element={<Maharashtra />} />
          <Route path="/NEIndia" element={<NEIndia />} />
          <Route path="/Karnataka" element={<Karnataka />} />
          <Route path="/Rajasthan" element={<Rajasthan />} />
          <Route path="/Treks" element={<Treks />} />
          <Route path="/Uttrakhand" element={<Uttrakhand />} />
         
        </Routes>
      </div>
    </Router>
  );
}

export default App;

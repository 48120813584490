import React, { useEffect, useState } from 'react';
import './Gallery.css';

const importAll = (r) => {
  const imagesByFolder = {};

  r.keys().forEach((path) => {
    const parts = path.split('/');
    const folderPath = parts.slice(1, parts.length - 1).join('-');
    const folderName = folderPath || 'root';

    if (!imagesByFolder[folderName]) {
      imagesByFolder[folderName] = [];
    }

    imagesByFolder[folderName].push(r(path));
  });

  return imagesByFolder;
};

const Gallery = () => {
  const [images, setImages] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoom, setZoom] = useState(1); // Initial zoom level

  useEffect(() => {
    const galleryImages = importAll(require.context('../Gallery', true, /\.(jpg|jpeg|png)$/));
    setImages(galleryImages);
  }, []);

  // Disable background scroll when modal is open
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
    setZoom(1); // Reset zoom when opening a new image
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setZoom(1); // Reset zoom when closing the modal
  };

  const handleZoom = (e) => {
    e.preventDefault();
    // Stop scrolling the background
    e.stopPropagation();
    
    if (e.deltaY < 0) {
      setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level: 3
    } else {
      setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level: 1
    }
  };

  const renderImages = (folder, images) => (
    <div className="gallery-section" key={folder}>
      <h1 className="gallery-subtitle">{folder}</h1>
      <div className="gallery-flex-container">
        {images.map((image, index) => (
          <div
            className="gallery-item"
            key={index}
            onClick={() => openModal(image)}
          >
            <img
              src={image}
              alt={`Gallery Image ${index + 1}`}
            />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="gallery-container">
      <h2 className="gallery-title">Gallery - Footprints of memories</h2>

      {Object.keys(images).length === 0 ? (
        <p>No images found.</p>
      ) : (
        Object.entries(images).map(([folder, images]) =>
          renderImages(folder, images)
        )
      )}

      {isModalOpen && (
        <div className="modal-gallary" onWheel={handleZoom}>
          <div className="modal-content-gallary">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="Selected"
              className="modal-image-gallary"
              style={{ transform: `scale(${zoom})` }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;

// We Provide..
import React from 'react';
import { useNavigate } from 'react-router-dom';

import honeymoonImg from '../Other Images/Honeymoon.jpg';
import CampingwithTrekking from '../Other Images/Camping with Trekking.jpg';
import bhaktiYatraImg from '../Other Images/Bhakti Yatra.jpg';
import Internationals from '../Other Images/Internationals.jpg';
import groupToursImg from '../Other Images/Group Tours.jpg';
import backpackingImg from '../Other Images/Camping.jpg';
import './Categories.css';

const Categories = () => {
  const navigate = useNavigate();

  const categories = [
    { name: 'Honeymoon', img: honeymoonImg },
    { name: 'Group Tours', img: groupToursImg },
    { name: 'Backpacking', img: backpackingImg },
    { name: 'Camping with Trekking', img: CampingwithTrekking },
    { name: 'Internationals', img: Internationals },
    { name: 'Bhakti Yatra', img: bhaktiYatraImg },
    
  ];

  const handleClick = (name) => {
    // Navigate to the CategoryDetails page
    navigate(`/category/${name}`);
  };

  return (
    <div className="categories-container">
      <h1 className="about-us-heading">We Provide</h1>
      <div className="categories-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-item"
            onClick={() => handleClick(category.name)}
          >
            <img src={category.img} alt={category.name} className="category-image" />
            <h3 className="category-title">{category.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;
